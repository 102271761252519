import React from "react";
import { HashRouter, Switch, Redirect } from "react-router-dom";
import LoadingBar from "./components/LoadingBar";
import { Route } from "react-router-dom";
import { isAuthenticate } from "./services/storage";
const Login = React.lazy(() => import("./views/login/Login"));
const Admin = React.lazy(() => import("./views/admin/Admin"));
const Error404 = React.lazy(() => import("./views/error/Error404"));
function App() {
  return (
    <HashRouter basename='/'>
      <React.Suspense fallback={<LoadingBar loading={true} />}>
        <Switch>
          <Route
            exact
            path='/'
            render={() =>
              isAuthenticate() ? (
                <Redirect to='/home' />
              ) : (
                <Redirect to='/login' />
              )
            }
          />
          <Route path='/login' name='Login' component={Login} />
          <Route path='/404' name='404' component={Error404} />
          <Admin fallback={<LoadingBar loading={true} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
